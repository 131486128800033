import clsx from 'clsx'
import { SharedTypographyProps } from './common'

/**
 * Headline/H1
 * font-family: "Ogg TRIAL";
 * font-size: 36px;
 * font-style: normal;
 * font-weight: 400;
 * line-height: 42px;
 * letter-spacing: 0.72px;
 */

export function HeadlineH1({ children, color, tag, className }: SharedTypographyProps) {
  const Tag = tag || 'h1'
  return (
    <Tag
      className={clsx(
        `font-ogg text-4xl font-normal leading-[42px] tracking-wide transition`,
        color && `text-${color}`,
        className
      )}
    >
      {children}
    </Tag>
  )
}
