import { HeadlineH1 } from '../../../common/component-library/typography/HeadlineH1'
import { LazyLoadingSpinner } from '../../../common/components/LazyLoadingSpinner'
import { EmailCapture } from '../atoms/EmailCapture'
import { useReconstructionHandler } from '../hooks/useReconstructionHandler'

export function UnderConstructionPage() {
  const { showLoadingUi } = useReconstructionHandler()
  return (
    <div className="flex flex-col items-center justify-center bg-[#8797a2] p-[25px] text-lg">
      {showLoadingUi ? (
        <LazyLoadingSpinner />
      ) : (
        <>
          <div className="pt-10 text-white">
            <HeadlineH1 className="m-w-[600px] mb-5 text-[40px] text-white">
              Axo by Upgrade Labs is Getting an Upgrade!
            </HeadlineH1>
          </div>

          <EmailCapture />
        </>
      )}
    </div>
  )
}
