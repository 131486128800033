'use client'
import * as React from 'react'
import { useIsMounted } from '../../../hooks/useIsMounted'

function UnmemoizedEmailCapture() {
  // #8797a2 bg color for form
  const isMounted = useIsMounted()
  if (!isMounted) return null

  return (
    <>
      <iframe
        src="https://api.leadconnectorhq.com/widget/form/2Kd6hQg3PPCHNPEoNwSs"
        id="inline-2Kd6hQg3PPCHNPEoNwSs"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Axo Email Capture"
        data-height="400"
        data-layout-iframe-id="inline-2Kd6hQg3PPCHNPEoNwSs"
        data-form-id="2Kd6hQg3PPCHNPEoNwSs"
        title="Axo Email Capture"
        style={{ width: '100%', minHeight: 600, maxHeight: '100dvh', border: 'none', borderRadius: '4px' }}
      ></iframe>
    </>
  )
}

export const EmailCapture = React.memo(UnmemoizedEmailCapture, () => true)
